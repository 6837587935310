export function minutesToHoursAndMinutes(minutes) {
  minutes = Math.abs(minutes);
  // Calculate the number of minutes that are left over after the whole hours have been accounted for
  const leftoverMinutes = minutes % 60;
  // Calculate the number of whole hours by dividing the total number of minutes by 60 and using integer division
  const hours = Math.floor(minutes / 60);
  // Convert the hours and minutes to strings
  let hoursString = hours.toString();
  if (hoursString.length == 1) {
    hoursString = "0" + hoursString;
  }
  let minutesString = leftoverMinutes.toString();
  if (minutesString.length == 1) {
    minutesString = "0" + minutesString;
  }
  // Concatenate the hours and minutes into a single string, separated by a colon
  const hoursAndMinutes = hoursString + minutesString;
  return hoursAndMinutes;
}

// val is a string
export function hoursAndMinsToMins(val) {
  val = val.replace(":", "");
  if (val.length > 4) {
    return null;
  }
  var hoursString;
  const size = val.length;
  if (val.length == 3) {
    hoursString = val[0];
  } else {
    hoursString = val.substring(0, 2);
  }
  let hoursInt = parseInt(hoursString);
  let mins = parseInt(val.substring(size - 2, size));
  return hoursInt * 60 + mins;
}

export function readableHoursAndMins(val) {
  let hoursMins = minutesToHoursAndMinutes(val);
  if (hoursMins.length == 3) {
    hoursMins = "0" + hoursMins;
  }
  let hours = parseInt(hoursMins.substring(0, 2));
  let suffix = "am";
  while (hours > 12) {
    hours = hours > 12 ? hours - 12 : hours;
    suffix = suffix == "am" ? "pm" : "am"; // reverse them
  }
  if (hours == 12) {
    suffix = suffix == "am" ? "pm" : "am"; // reverse them
    if (suffix == "am") {
      return "Midnight";
    }
  }
  let mins = hoursMins.substring(2, 4);
  return `${hours}:${mins} ${suffix} `;
}

export function getDateFromString(stamp) {
  var splits = stamp.split("T");
  return splits[0];
}

export function getTimeFromString(stamp) {
  var splits = stamp.split("T");
  if (splits.length < 2) {
    return null;
  }
  var time = splits[1];
  var tSplits = time.split(":");
  if (tSplits.length >= 2) {
    let timeString = tSplits[0] + ":" + tSplits[1];
    if (timeString.length == 4) {
      // convert 4:00 to 04:00
      timeString = "0" + timeString;
    }
    return timeString;
  }
}
export function getDefaultProductFromProducts(products) {
  if (products.length == 0) {
    return null;
  }
  if (products.length == 1) {
    return products[0];
  }
  const filtered = products.filter((item) => {
    return item.is_default;
  });
  if (filtered.length == 1) {
    return filtered[0];
  }
  return null;
}

export function getDateTimeParamsFromCalendarTimeObj(timeObj) {
  if (!timeObj || !timeObj.startStr) {
    return null;
  }
  const startStr = timeObj.startStr;
  const parts = startStr.split("T");
  const date = parts[0];
  const timeParts = parts[1].split(":");
  const time = `${timeParts[0]}:${timeParts[1]}`;
  let duration_in_hours = 3;
  if (timeObj.endStr) {
    let endTimeStr = timeObj.endStr;
    const diff = new Date(endTimeStr) - new Date(startStr);
    duration_in_hours = diff / 1000 / 3600;
  }
  return {
    date: date,
    time: time,
    duration_in_hours: duration_in_hours,
  };
}

export default {
  minutesToHoursAndMinutes,
  hoursAndMinsToMins,
  readableHoursAndMins,
  getDateFromString,
  getTimeFromString,
  getDefaultProductFromProducts,
  getDateTimeParamsFromCalendarTimeObj,
};
