export function getPic(space) {
  const pic = space.pic;
  if (!pic) {
    return "";
  }
  if (pic.pathname) {
    return "/static/tizi/images/" + pic.pathname;
  } else if (pic.data) {
    return pic.data;
  } else if (space.space_company.logo) {
    return getPic(space.space_company.logo);
  }
  return "";
}

export function getTodaysDate() {
  var today = new Date();
  var day = ("0" + today.getDate()).slice(-2);
  var month = parseInt(today.getMonth()) + 1;
  month = ("0" + month).slice(-2);
  var year = today.getFullYear();
  // return `${day}-${month}-${year}`;
  return `${year}-${month}-${day}`;
}

export function getDefaultProduct(space) {
  if (space.products.length == 1) {
    return space.products[0];
  }
  const filtered = space.products.filter((item) => {
    return item.is_default;
  });
  if (filtered.length == 1) {
    return filtered[0];
  }
  return null;
}

export default {
  getPic,
  getTodaysDate,
  getDefaultProduct,
};
