import moment from "moment";
export default {
  getDateFromFullDateTime(timeString) {
    if (!timeString || typeof timeString != "string") {
      return null;
    }
    return timeString.split("T")[0];
  },
  getHourMinFromFullDateTime(timeString) {
    if (!timeString) {
      return null;
    }
    const parts = timeString.split("T");
    if (parts.length <= 1) {
      return null;
    }
    let timePart = parts[1];
    timePart = timePart.split(/[+|-]/)[0];
    const hourMinParts = timePart.split(":");
    return `${hourMinParts[0]}:${hourMinParts[1]}`;
  },
  makeReadableDateTime(v) {
    if (!v) {
      return v;
    }
    const val = moment(v);
    return val.format("ddd, D MMM Y, h:mm a");
  },
  makeReadableDate(v) {
    if (!v) {
      return v;
    }
    const val = moment(v);
    return val.format("ddd, D MMM Y");
  },
  makeReadableTime(v) {
    if (!v) {
      return v;
    }
    const val = moment(v);
    return val.format("h:mm a");
  },
  replaceHref(instance, data) {
    let currentQuery = instance.$route.query;
    currentQuery = { ...currentQuery, ...data };
    const newHref = instance.$router.resolve({ query: currentQuery }).href;
    const oldHref = `${window.location.pathname}${window.location.search} `;
    if (newHref !== oldHref) {
      window.history.replaceState("data", document.title, newHref);
    }
  },
  padHours(val) {
    if (val.toString().length === 1) {
      return `0${val} `;
    }
    return val;
  },
};
